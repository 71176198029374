import { Box, Flex } from '@rebass/grid'
import React, { Component } from 'react'

import BlogContainer from '../components/blog-page/PageBlog/BlogContainer'
import BlogRelated from '../components/blog-page/PageBlog/BlogRelated'
import BlogSubscribeRow from '../components/blog-page/PageBlog/BlogSubscribeRow'
import Image from '../components/page-image-new'
import Image1 from '../images/resources/happy_haus_darlington_047.jpg'
import Image2 from '../images/resources/happy-haus-helensvale-10.jpg'
import Image3 from '../images/resources/happyhaus-gallery-2019-07-24-photo-strong-knockdown-1640.jpg'
import Image4 from '../images/resources/happy-haus-south-stradbroke-6.jpg'
//import PageOverview from '../components/page-overview';
import PageResourceCards from '../components/page-resource-cards'
import Row from '../components/page-content-row'
import SEO from '../components/seo'
import Title from '../components/page-title'
import styled from 'styled-components'
import { theme } from '../util/style'
import { graphql } from 'gatsby'
import { getRelatedPostData } from '../util/related-post'

const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
  `https://happyhaus.com.au${Image3}`,
  `https://happyhaus.com.au${Image4}`,
]

const ResourceImage = 'resources/happy_haus_darlington_047.jpg'
const ResourceImage2 = 'resources/happy-haus-helensvale-10.jpg'

const ResourceImage3 =
  'resources/happyhaus-gallery-2019-07-24-photo-strong-knockdown-1640.jpg'

const ResourceImage4 = 'resources/happy-haus-south-stradbroke-6.jpg'

class Page extends Component {
  render() {
    const { data } = this.props

    const relatedPosts = getRelatedPostData(data)

    return (
      <>
        <SEO
          title="Resources"
          description="We believe more Australians should have access to good design so we share insights and inspiration to help you throughout your decision making process."
          images={ogImages}
        />

        <Row>
          <PageHeading
            mt={['35px', '85px']}
            as="h1"
            px={2}
            mb={['38px', '50px']}
          >
            We believe in more Australians having access to good design, so we
            share insights to help with the decision-making process.
          </PageHeading>
          <BlogContainer pb={['30px']} attachTop>
            <BlogSubscribeRow
              data={{
                image: {
                  file: {
                    path: '/images/resource-thumb-square-metre-rates.jpg',
                  },
                },
                copyHtml:
                  'Learn why square metre rates don’t always measure up',
                buttonText: 'Continue',
                theme: 'Square Metre Rates',
                hubspotConversionType: 'Square Metre Rates Guide',
              }}
            />
          </BlogContainer>
          <BlogContainer pb={['30px']} attachTop>
            <BlogSubscribeRow
              data={{
                image: {
                  file: {
                    path: '/images/resource-thumb-subtropical-homes.jpg',
                  },
                },
                copyHtml: 'Read about designing for living in the subtropics',
                buttonText: 'Continue',
                theme: 'Single Level Living',
                hubspotConversionType: 'Subtropics',
              }}
            />
          </BlogContainer>
          <BlogContainer attachTop>
            <BlogSubscribeRow
              data={{
                image: {
                  file: { path: '/images/resource-thumb-custom-haus.jpg' },
                },
                copyHtml: 'Learn about the custom haus process',
                buttonText: 'Continue',
                theme: 'Subtropical Homes',
                hubspotConversionType: 'Custom Haus Guide',
              }}
            />
          </BlogContainer>
          <BlogRelated data={{ relatedPosts }} />
        </Row>
        {/* <PageResourceCards /> */}
      </>
    )
  }
}

export default Page

export const query = graphql`
  {
    allContentfulOptions {
      edges {
        node {
          articlesOrder {
            id
            slug
            title
            category
            thumbnail {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`

const PageHeading = styled(Box)`
  font-weight: normal;
  font-family: ${theme.font.fontFamilySerifThin};
  font-size: 30px;
  line-height: 140%;
  width: 50%;

  @media (max-width: 800px) {
    width: 90%;
  }
`
