import React from 'react'
import BlogRow from '../BlogRow'
import BlogMedia from '../BlogMedia'
import BlogMediaCarouselFull from '../BlogMediaCarouselFull'

const BlogMediaRowFull = ({ data }) => {
  const { media, style, video, mediaCaption, images, videoCover } = data

  const dataStyle =
    String(style).toLowerCase() === 'inset'
      ? { width: 10 / 12, justifyContent: 'center' }
      : {}

  if (images)
    return (
      <BlogMediaCarouselFull
        images={images}
        caption={mediaCaption?.mediaCaption}
      />
    )

  return (
    <BlogRow>
      <BlogMedia
        data={{
          caption: mediaCaption?.mediaCaption,
          image: media,
          video: video,
          videoCover,
          ...dataStyle,
        }}
      />
    </BlogRow>
  )
}

export default BlogMediaRowFull
