import { OutlineButton, theme } from '../util/style'
import React, { Component } from 'react'
import { graphql } from 'gatsby'
import BlogContainer from '../components/blog-page/PageBlog/BlogContainer'
import BlogCopyRow from '../components/blog-page/PageBlog/BlogCopyRow'
import BlogMediaRowFull from '../components/blog-page/PageBlog/BlogMediaRowFull'
import BlogQuoteRow from '../components/blog-page/PageBlog/BlogQuoteRow'
import BlogRelated from '../components/blog-page/PageBlog/BlogRelated'
import BlogSubscribeRow from '../components/blog-page/PageBlog/BlogSubscribeRow'
import BlogTitleRow from '../components/blog-page/PageBlog/BlogTitleRow'
import { Box } from '@rebass/grid'
import CopyStackRow from '../components/v2/CopyStackRow'
import Coverflow from '../components/v2/Coverflow'
import Image1 from '../images/etc/happy-haus-hero-5.jpg'
import Image2 from '../images/etc/happy-haus-hero-8.jpg'
import { Link } from 'gatsby'
import PageHeader from '../components/v2/PageHeader'
import SEO from '../components/seo'
import slug from 'slug'
import styled from 'styled-components'
import { getRelatedPostData } from '../util/related-post'

const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
]

class Page extends Component {
  render() {
    const { data } = this.props

    const relatedPosts = getRelatedPostData(data)

    return (
      <>
        <SEO title="Specifications" description="" images={ogImages} />
        <PageHeader title={'Specifications'} />
        <BlogContainer borderTop pb={['36px', '86px']}>
          <BlogQuoteRow
            data={{
              content: (
                <>
                  <p>
                    Our team has curated quality brands for detailed equipment
                    and material specifications so we can cost our designs
                    accurately from the outset.
                  </p>
                </>
              ),
            }}
          />
        </BlogContainer>
        <BlogContainer>
          <BlogMediaRowFull
            data={{
              mediaCaption: {
                mediaCaption: <>James Haus, New Farm.</>,
              },
              images: [
                {
                  file: {
                    url: '/images/custom-haus/happyhaus-gallery-2024-02-22-photo-flower-1110871.jpg',
                  },
                  fixed: {
                    width: 1078,
                    height: 720,
                  },
                },
                {
                  file: {
                    url: '/images/custom-haus/happyhaus-gallery-2024-05-13-photo-deloraine-1140997.jpg',
                  },
                  fixed: {
                    width: 480,
                    height: 720,
                  },
                },
                {
                  file: {
                    url: '/images/custom-haus/happyhaus-gallery-2024-02-02-photo-james-1100686.jpg',
                  },
                  fixed: {
                    width: 1078,
                    height: 720,
                  },
                },
                {
                  file: {
                    url: '/images/custom-haus/happyhaus-gallery-2023-03-15-photo-silex-JG-1010211.jpg',
                  },
                  fixed: {
                    width: 480,
                    height: 720,
                  },
                },
                {
                  file: {
                    url: '/images/custom-haus/happyhaus-gallery-2024-01-22-photo-gordon-1100256.jpg',
                  },
                  fixed: {
                    width: 1078,
                    height: 720,
                  },
                },
                {
                  file: {
                    url: '/images/custom-haus/happyhaus-gallery-2024-02-02-photo-james-1100640.jpg',
                  },
                  fixed: {
                    width: 480,
                    height: 720,
                  },
                },
                {
                  file: {
                    url: '/images/custom-haus/happyhaus-gallery-2024-05-13-photo-deloraine-1140963-2.jpg',
                  },
                  fixed: {
                    width: 1078,
                    height: 720,
                  },
                },
                {
                  file: {
                    url: '/images/custom-haus/happyhaus-gallery-2023-07-28-photo-welbeck-1000967.jpg',
                  },
                  fixed: {
                    width: 480,
                    height: 720,
                  },
                },
              ],
            }}
          />
        </BlogContainer>
        <BlogContainer>
          <BlogCopyRow
            data={{
              images: [
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-02-15-photo-tarragindi-1110214.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-02-22-photo-flower-1110981.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2023-03-15-photo-silex-JG-1010441.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2022-09-20-photo-orari-JG-1080091.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-02-02-photo-james-1100713.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-05-23-photo-olive-1150588.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2023-03-15-photo-silex-JG-1010433.jpg',
                  },
                },
                {
                  fluid: {
                    src: '/images/custom-haus/happyhaus-gallery-2024-02-02-photo-james-1100590.jpg',
                  },
                },
              ],

              copyHtml: (
                <div>
                  <p>
                    A Happy Haus is beautiful yet practical. It is a home that
                    engages with its site characteristics and surrounds to
                    maximise the benefits of living in the subtropics. On the
                    inside, our designs are complete with refined interior
                    design and carefully curated products.
                  </p>
                  <p>
                    Our design process starts with quality brands for detailed
                    equipment and material specifications so we can cost our
                    designs accurately from the outset. We choose trusted brands
                    like Dulux, Colorbond, Ilve, Polytec and ABI. At the right
                    time in the process, these specifications can be changed to
                    meet the brief or aesthetics.
                  </p>
                  <a
                    href="/pdfs/happyhaus-specifications.pdf"
                    style={{ borderBottom: 0 }}
                  >
                    <OutlineButton>
                      Specification{' '}
                      <span
                        style={{
                          fontFamily: theme.font.fontFamilyABCLight,
                          color: theme.colors.grey11,
                          paddingLeft: '8px',
                        }}
                      >
                        ↓
                      </span>
                    </OutlineButton>
                  </a>
                </div>
              ),
            }}
          />
        </BlogContainer>
        <BlogContainer>
          <BlogSubscribeRow
            data={{
              image: {
                file: { path: '/images/custom-haus/subscribe-graphic.png' },
              },
              copyHtml: 'Learn about the custom haus process',
              buttonText: 'Download',
              theme: 'Subtropical Homes',
              hubspotConversionType: 'Custom Haus Guide',
            }}
          />
        </BlogContainer>
        <BlogContainer attachTop>
          <BlogMediaRowFull
            data={{
              style: 'inset',
              media: {
                file: {
                  path: '/images/custom-haus/happyhaus-gallery-2024-02-22-photo-flower-1110986.jpg',
                },
              },
            }}
          />
        </BlogContainer>
        <BlogContainer attachTop pb={['46px', '86px']}>
          <BlogQuoteRow
            data={{
              content: (
                <>
                  <p>
                    If you’re considering a Happy Haus or about to embark on a
                    new haus project, we can’t wait to say,{' '}
                    <i>“Welcome home!”</i>
                  </p>
                  <h5>LACHLAN & RICK</h5>
                </>
              ),
            }}
          />
        </BlogContainer>
        <BlogRelated data={{ relatedPosts }} />
      </>
    )
  }
}

export default Page

export const query = graphql`
  {
    allContentfulOptions {
      edges {
        node {
          articlesOrder {
            id
            slug
            title
            category
            thumbnail {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`

const ImageWrapper = styled(Box)`
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
  }
`
