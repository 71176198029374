import React, { Component } from 'react'

import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'

import Title from '../components/page-title'
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'
import PageCtaBoxes from '../components/page-cta-boxes'
import PageOverview from '../components/page-overview'
import PageRuledList from '../components/page-ruled-list'
import { fontLight, fontRegular, theme } from '../util/style'

import Image from '../components/page-image-new'

import Image1 from '../images/etc/happyhaus-gallery-2023-08-24-photo-gordon-1020249.jpg'
import styled from 'styled-components'
import HalfGridRow from '../components/half-grid-row'
import HalfGridRowItem from '../components/half-grid-row/half-grid-row-item'
import PageHeader from '../components/v2/PageHeader'
import BlogContainer from '../components/blog-page/PageBlog/BlogContainer'
import CopyStackRow from '../components/v2/CopyStackRow'
import { graphql, Link } from 'gatsby'
import BlogRelated from '../components/blog-page/PageBlog/BlogRelated'
import { getRelatedPostData } from '../util/related-post'
const ogImages = [`https://happyhaus.com.au${Image1}`]

const ImageWork = 'etc/happyhaus-gallery-2022-11-25-photo-flower-1110443.jpg'

//<PageContact  submitted={false} />

class Page extends Component {
  render() {
    const { data } = this.props
    const relatedPosts = getRelatedPostData(data)

    return (
      <>
        <SEO
          title="Join our team"
          description="We're always on the lookout for talented people to join our sales, design and build teams. Contact us with your expression of interest and CV via homes@happyhaus.com.au"
          images={ogImages}
        />
        <PageHeader
          title={'Join our team'}
          style={{ fontFamily: theme.font.fontFamilyLight }}
        />
        <BlogContainer attachTop pb={['44px', '94px']}>
          <CopyStackRow
            items={[
              {
                heading: 'Construction Supervisor',
                content: (
                  <>
                    <p>
                      We are looking for a talented Construction Supervisor who
                      is experienced in their craft to join a team of
                      established builders, carpenters, administrators, and
                      designers who are all working to achieve great client
                      outcomes. We are a fully integrated design + build
                      company. We design for building eﬀiciency in high-end
                      custom homes. We prioritise craft and quality.
                    </p>
                    <p>
                      You will collaborate with the Director of Construction,
                      run your own projects within the HH framework and utilise
                      full construction systems and support. Enjoy great clients
                      following a solid customer process and a steady pipeline
                      of great projects into the future within Brisbane CBD.
                      This opportunity is for you if you live to create
                      beautiful living spaces.
                    </p>
                    <p>
                      Let's chat if you're looking for a change or want to build
                      architectural homes with us.
                    </p>
                    <p>
                      Read about the role{' '}
                      <a href="/pdfs/happyhaus-hiring-construction-supervisor.pdf">
                        here
                      </a>{' '}
                      (PDF) and apply via{' '}
                      <a href="mailto:homes@happyhaus.com.au">
                        homes@happyhaus.com.au
                      </a>
                    </p>
                  </>
                ),
              },
            ]}
          />
        </BlogContainer>

        <BlogContainer attachTop pb={['44px', '94px']}>
          <CopyStackRow
            items={[
              {
                heading: 'Carpenter',
                content: (
                  <>
                    <p>
                      We’re looking for a talented Carpenter experienced in
                      their craft. Join a team of long-term Happy Haus
                      carpenters. Our in-haus design team support all projects.
                      We design for build efficiency in high-end homes. Our
                      priority is quality and craftsmanship.
                    </p>
                    <p>
                      All of our projects are within 15 km of Brisbane CBD. We
                      offer competitive wages based on skill-level.
                    </p>
                    <p>
                      Let's chat if you're looking for a change or want to build
                      architectural homes with us.
                    </p>
                    <p>
                      Read about the role{' '}
                      <a href="/pdfs/happyhaus-hiring-carpenter.pdf">here</a>{' '}
                      (PDF) and apply via{' '}
                      <a href="mailto:homes@happyhaus.com.au">
                        homes@happyhaus.com.au
                      </a>
                    </p>
                  </>
                ),
              },
            ]}
          />
        </BlogContainer>
        <Row>
          <Flex flexWrap="wrap">
            <Box width={[1]} px={2} mr={[0, 0]}>
              <Image src={ImageWork} alt="Work Image" />
            </Box>
          </Flex>
        </Row>
        <BlogRelated data={{ relatedPosts }} />
      </>
    )
  }
}

export default Page

export const query = graphql`
  {
    allContentfulOptions {
      edges {
        node {
          articlesOrder {
            id
            slug
            title
            category
            thumbnail {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`

const HorizontalRule = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #888;
  margin: 1em 0;
  padding: 0;
`

const Notifbar = styled(Box)`
  background-color: #303030;
  color: white;
  text-align: center;
  font-size: 17px;
  ${fontRegular}

  a {
    color: white;
  }
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999999;
`

const List = styled(Box)`
  li {
    padding-bottom: 0.8rem;
  }
`

const CopyWrapper = styled(Box)`
  font-family: ${theme.font.fontFamilySerifThin};
  text-align: left;
  font-size: 22px;
  line-height: 130%;

  h3 {
    font-size: 30px;
    line-height: 130%;
    font-weight: normal;
    @media (max-width: 800px) {
      font-size: 24px;
    }
  }

  p {
    margin-top: 0%;
    margin-bottom: 1rem;
    width: 100%;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  a {
    border-bottom: 1px solid ${theme.colors.grey11};
    color: ${theme.colors.grey1} !important;
    &:hover {
      border-bottom: 1px solid ${theme.colors.grey2};
    }
  }
`
