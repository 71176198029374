import { graphql } from 'gatsby'

export const getRelatedPostData = (data, category) => {
  const articlesOrder =
    data?.allContentfulOptions?.edges?.[0]?.node?.articlesOrder

  if (!articlesOrder) {
    return []
  }

  const articles = articlesOrder.slice(0, 2)

  const relatedPosts = articles.map((edge) => ({
    slug: edge?.slug || '',
    title: edge?.title || '',
    category: edge?.category || [],
    thumbnail: edge?.thumbnail || '',
  }))

  return relatedPosts
}
