import { Box, Flex } from '@rebass/grid'
import React, { useEffect, useRef, useState } from 'react'
import Hls from 'hls.js'
import IconPlay from '../../../svg/icon-play'
import styled from 'styled-components'
import { theme } from '../../../../util/style'

const BlogVideo = ({ data }) => {
  const videoRef = useRef(null)
  const { width = 1, justifyContent, alignItems, caption, videoCover } = data
  const muxVideoSrc = `https://stream.mux.com/${data.video.playbackId}.m3u8`

  const videoCoverUrl = videoCover ? videoCover?.file?.url : null

  const thumbnail =
    videoCoverUrl ||
    `https://image.mux.com/${data.video.playbackId}/thumbnail.jpg`

  const [isPlaying, setIsPlaying] = useState(false)
  const [isSeeking, setIsSeeking] = useState(false)

  useEffect(() => {
    let hls
    if (videoRef.current) {
      const video = videoRef.current
      if (Hls.isSupported()) {
        hls = new Hls()
        hls.loadSource(muxVideoSrc)
        hls.attachMedia(video)
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = muxVideoSrc
      }
    }
    return () => {
      if (hls) {
        hls.destroy()
      }
    }
  }, [videoRef.current])

  useEffect(() => {
    if (videoRef?.current) {
      if (isPlaying && !isSeeking) {
        videoRef.current.play()
      } else {
        videoRef.current.pause()
      }
    }
  }, [isPlaying, isSeeking, videoRef?.current])

  return (
    <Flex justifyContent={justifyContent} alignItems={alignItems}>
      <Box width={[1, width]} style={{ position: 'relative' }}>
        <AspectRatioWrapper ratio={data.video.ratio}>
          <VideoWrapper padding={0} margin={0}>
            {!isPlaying && (
              <PlayButton
                onClick={() => setIsPlaying((prev) => !prev)}
                thumbnail={thumbnail}
              >
                <IconPlay />
              </PlayButton>
            )}

            <video
              width="100%"
              height="100%"
              controls={isPlaying}
              ref={videoRef}
              onPause={() => {
                if (!isSeeking) {
                  setIsPlaying(false)
                }
              }}
              onSeeking={() => setIsSeeking(true)}
              onSeeked={() => {
                setIsSeeking(false)
                setIsPlaying(true)
              }}
            />
          </VideoWrapper>
        </AspectRatioWrapper>
        {caption && (
          <Figcaption>
            <Arrow>↑</Arrow> {caption}
          </Figcaption>
        )}
      </Box>
    </Flex>
  )
}

export default BlogVideo

const VideoWrapper = styled(Box)`
  position: relative;

  video {
    display: block;
  }
`

const PlayButton = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  z-index: 10;

  background-image: ${({ thumbnail }) => `url(${thumbnail})`};
  background-size: cover;
  background-position: center;
`

const Figcaption = styled.div`
  color: #888;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 17.5px */
  margin-top: 0.7em;
  position: absolute;
`

const Arrow = styled.span`
  font-family: ${theme.font.fontFamilyABCLight};
`

const AspectRatioWrapper = styled.div`
  position: relative;
  width: 100%;

  padding-top: ${({ ratio }) => {
    if (!ratio) {
      return ''
    }
    const [width, height] = ratio?.split(':')?.map(Number)

    if (!width || !height) {
      return ''
    }

    return `${(height / width) * 100}%`
  }};
  overflow: hidden;

  ${VideoWrapper} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
