import 'swiper/swiper.min.css'

import { Pagination, PaginationNext, PaginationPrev } from './Pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useEffect, useRef, useState } from 'react'

import { Box } from '@rebass/grid'
import React from 'react'
import styled from 'styled-components'
import useWindowSize from '../../../../hooks/useWindowSize'
import { theme } from '../../../../util/style'

const BlogMediaCarouselFull = ({ images, caption }) => {
  const [swiper, setSwiper] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.realIndex)
  }

  const { width } = useWindowSize()

  if (!images || typeof window == `undefined`) return null

  const isMobile = width < 800

  return (
    <Wrapper>
      {currentIndex !== 0 && <PaginationPrev swiper={swiper} />}
      {currentIndex + 1 < images.length && <PaginationNext swiper={swiper} />}
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={6}
        onSwiper={setSwiper}
        onSlideChange={handleSlideChange}
        speed={800}
      >
        {images?.map((image) => {
          const slideWidth = isMobile
            ? (image?.fixed?.width / image?.fixed?.height) * (width * 0.55) +
              'px'
            : (image?.fixed?.width / image?.fixed?.height) * 800 + 'px'

          return (
            <SwiperSlide
              key={image.id}
              style={{
                width: slideWidth,
              }}
            >
              <img
                src={image.file.url}
                alt={image.description}
                style={{
                  width: slideWidth,
                  height: 'auto',
                }}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
      <CaptionWrapper>
        {caption && (
          <Caption>
            <Arrow>↑</Arrow> {caption}
          </Caption>
        )}
        <Pagination
          images={images}
          currentIndex={currentIndex}
          swiper={swiper}
        />
      </CaptionWrapper>
    </Wrapper>
  )
}
export default BlogMediaCarouselFull

const CaptionWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
`

const Wrapper = styled(Box)`
  position: relative;
  width: 100%;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    img {
      display: block;
    }
  }
`

const Caption = styled.div`
  color: #888;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 17.5px */
  margin-top: 0.7em;

  @media (max-width: 800px) {
    margin-top: 0;
    font-size: 12px;
  }
`

const Arrow = styled.span`
  font-family: ${theme.font.fontFamilyABCLight};
`
